
import { Header } from "../components/header/Header";
import { Footer } from '../components/footer/Footer';
export function Contact() {
    return (
        <>
            <Header />
            <Footer />
        </>
    )
}